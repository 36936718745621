<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: '',
      addType: 'Add Courses',
      addComponentName: 'add-courses',
      editComponent: 'edit-courses',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name_en', label: 'Name EN' },
        { key: 'name_ar', label: 'Name Ar' },
        { key: 'description_en', label: 'Description EN' },
        { key: 'description_ar', label: 'Description AR' },
        { key: 'issuer', label: 'Issuer' },
        { key: 'expired_at', label: 'Expired At' },
        { key: 'document', label: 'Document' },
        { key: 'actions' },
        // { key: 'email', label: 'Name En' },
        // { key: 'coruses', label: 'Coruses' },
      ],
    }
  },

  computed: {

    apiUrl() {
      let base = `user/${this.$route.params.C_id}/certificate?`

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },

  methods: {

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },

  },
}
</script>

  <style></style>
